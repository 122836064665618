

import AppBar from 'components/UI/AppBar';
import HamburgerButton from 'components/UI/HamburgerButton';
import SearchBar from 'containers/SearchBar';
import DarkModeToggle from 'containers/DarkModeToggle';
import TheUser from 'containers/TheUser';
import { getLogo } from "src/LogoGenerator";

const logoPath = () => {
  return 'https://piracyplus.com/assets/images/' + getLogo();
}

const BurgerHeader = ({ openMenu }) => (
  <>
    <AppBar>
      <HamburgerButton onClick={openMenu} />
      <div>
        <a href="https://piracyplus.com">
          <img src={logoPath()} alt="Piracy Plus Logo" height="32"></img>  
        </a>
      </div>
      <div className='sticky-bar-widgets-container'>
        <SearchBar id='mobile' />
      </div>
    </AppBar>
    <style jsx>{`
      .sticky-bar-widgets-container {
        display: flex;
        align-items: center;
      }

      .sticky-bar-widgets-container > :global(*:not(:first-child)) {
        margin-left: 8px;
      }
    `}</style>
  </>
);

export default BurgerHeader;

/*
        <DarkModeToggle
          id='mobile'
          className='left-margin' />
        <TheUser />
*/