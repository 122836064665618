import { getPoster } from "src/LogoGenerator";
const posterImage = getPoster();

const NOTHING_PLACEHOLDER_IMAGE_PATH = 'https://piracyplus.com/assets/images/' + posterImage;
const PROFILE_PLACEHOLDER_IMAGE_PATH = '/assets/svgs/person.svg';
const ERROR_IMAGE_PATH = '/assets/svgs/error.svg';
const LOGO_IMAGE_PATH = '/assets/svgs/logo.svg';
const DARK_TMDB_IMAGE_PATH = '/assets/svgs/tmdbgreen.svg';
const LIGHT_TMDB_IMAGE_PATH = '/assets/svgs/tmdb.svg';

export {
  NOTHING_PLACEHOLDER_IMAGE_PATH,
  PROFILE_PLACEHOLDER_IMAGE_PATH,
  ERROR_IMAGE_PATH,
  LOGO_IMAGE_PATH,
  DARK_TMDB_IMAGE_PATH,
  LIGHT_TMDB_IMAGE_PATH
};
