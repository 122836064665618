

import { useState } from 'react';

const FooterStyle = {
  width: "100%",
  padding: "0px 15px 10px",
  fontSize: "10px",
  textAlign: "center"
 }

const Footer = ({ className }) => {
  const [opened, setOpened] = useState(false);

  const openMenuHandler = () => {
    setOpened(true);
  };

  const closeMenuHandler = () => {
    setOpened(false);
  };

  return (
    <div>
      <p style={FooterStyle}>
        Piracy Plus was created by the inventor and copyright owner of the world's first online movie theatre and streaming service.  Piracy Plus encourages filmmakers and distributors to utilize <a href="https://cinedapt.com/?piracy-plus" target="_blank">Cinedapt</a>, the only patented and proven solution against film piracy.  Streaming and purchase information on Piracy Plus is provided through JustWatch.
      </p>
    </div>
  );
};

export default Footer;
