export const getLogo = () => {
    var value = Math.floor(Math.random() * 10);
    var logos = [
        'piracy-plus-logo-a.png',
        'piracy-plus-logo-b.png',
        'piracy-plus-logo-c.png',
        'piracy-plus-logo-d.png',
        'piracy-plus-logo-e.png',
        'piracy-plus-logo-f.png',
        'piracy-plus-logo-g.png',
        'piracy-plus-logo-h.png',
        'piracy-plus-logo-i.png',
        'piracy-plus-logo-j.png'
    ];
    return logos[value];
}

export const getPoster = () => {
    var value = Math.floor(Math.random() * 10);
    var posters = [
        'piracy-plus-poster-a.png',
        'piracy-plus-poster-b.png',
        'piracy-plus-poster-c.png',
        'piracy-plus-poster-d.png',
        'piracy-plus-poster-e.png',
        'piracy-plus-poster-f.png',
        'piracy-plus-poster-g.png',
        'piracy-plus-poster-h.png',
        'piracy-plus-poster-i.png',
        'piracy-plus-poster-j.png'
    ];
    return posters[value];
}

export const getShare = () => {
    var value = Math.floor(Math.random() * 10);
    var shares = [
        'piracy-plus-share-a.png',
        'piracy-plus-share-b.png',
        'piracy-plus-share-c.png',
        'piracy-plus-share-d.png',
        'piracy-plus-share-e.png',
        'piracy-plus-share-f.png',
        'piracy-plus-share-g.png',
        'piracy-plus-share-h.png',
        'piracy-plus-share-i.png',
        'piracy-plus-share-j.png'
    ];
    return shares[value];
}

//LogoGenerator
export default { getLogo, getPoster, getShare };