import React, { useEffect, useState } from 'react';
import Router, { useRouter } from 'next/router';
import Head from 'next/head';
import HeadData from 'components/HeadData';
import { useDispatch, useSelector } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';

import PageWrapper from 'parts/PageWrapper';
import Loader from 'components/UI/Loader';
import RecommendedMovieList from 'components/RecommendedMovieList';
import MovieSummary from 'components/MovieSummary';
import MovieStreaming from 'components/MovieStreaming';
import getMovie from 'actions/getMovie';
import getRecommendedMovies from 'actions/getRecommendedMovies';
import clearRecommendedMovies from 'actions/clearRecommendedMovies';
import clearMovie from 'actions/clearMovie';
import QUERY_PARAMS from 'utils/constants/query-params';
import LINKS from 'utils/constants/links';
import checkEmptyObject from 'utils/helpers/checkEmptyObject';

import { sendAllRequest, sendGetRequest, getMovieData, formatDate } from 'src/MovieData.js';
import { getDescription } from 'src/CinedaptData.js';


export const getStaticPaths = async () => {
  const moviesData = await sendAllRequest();
  //const moviesData = await sendGetRequest('mc');
  var paths = [];
  for (const [key, value] of Object.entries(moviesData)) {
    paths.push( { params: { pid: `${key }` }  } );
  }
  return {
    paths,
    fallback: true
  }
}

export const getStaticProps = async (context) => {
  const pid = context.params.pid;
  const data = await getMovieData(pid);
  return {
    props: { movies: data }
  }
}

function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

const Movie = () => {
  const dispatch = useDispatch();
  const general = useSelector(state => state.general);
  const movie = useSelector(state => state.movie);
  const recommendedMovies = useSelector(state => state.recommendedMovies);
  const [movieContent, setMovieContent] = useState(null);
  const [movieId, setMovieID] = useState(null);
  const [page, setPage] = useState(null);
  const { query } = useRouter();

  async function getMovieContent(pid) {
    if (movieContent === null) {

        var test = isNumeric(pid);
        console.log('isNumeric', test);

        const data = await getMovieData(pid);
        setMovieContent(data);
        setMovieID(data.id);

        const initialMovieId = movieId;
        const initialPage = Router.query[QUERY_PARAMS.PAGE];
    }
  }

  useEffect(() => {
    return () => {
      dispatch(clearMovie());
      dispatch(clearRecommendedMovies());
    };
  }, [dispatch]);

  useEffect(() => {
    if (checkEmptyObject(query)) return;

    const { pid } = query;

    console.log('here', QUERY_PARAMS);
    console.log('here', pid);

    if (pid) {
        getMovieContent(pid);
    }
  }, [dispatch, query]);

  useEffect(() => {
    if (!movieId) return;

    scroll.scrollToTop({smooth: true, delay: 500});
    
    dispatch(getMovie(movieId));
  }, [movieId, dispatch]);

  useEffect(() => {
    if (!movieId || !page) return;
    dispatch(getRecommendedMovies(movieId, page));
  }, [movieId, page, dispatch]);

  if (movie.loading) {
    return <Loader />;
  }

  if(query.isFallback) {
    return <h1>Loading...</h1>
  }

  console.log(movie);

  const { secure_base_url: baseUrl } = general.base.images;
  const description = getDescription(movie);

  var headerData = {
    title: 'Piracy Plus - ' + movie.title + ' - Movie Library',
    description: description,
    image: '/assets/piracy-plus-share.png',
    slug: 'movie/' + query.pid
  };

  return (
    <PageWrapper>
      <HeadData headerData={headerData} />
      <MovieSummary
        baseUrl={baseUrl}
        movie={movie} />
      <MovieStreaming
        baseUrl={baseUrl}
        movie={movie} />
      <RecommendedMovieList
        baseUrl={baseUrl}
        recommendedMovies={recommendedMovies} />
    </PageWrapper>
  );
};

export default Movie;